<script>
  import { get } from 'vuex-pathify'
  import {
    TRANSITION_LEAVE_DURATION,
    TRANSITION_ENTER_DURATION,
    DIRECTION_BACK,
    DIRECTION_FORWARD,
  } from '@/router'

  /**
   * A fade-and-slide transition for views.
   */
  export default {
    props: {
      /** Whether the transition should be applied on the initial render of a node. */
      appear: {
        type: Boolean,
        default: false,
      },
      /** Whether to omit emitting the `navigationTransitionLeave` and `navigationTransitionEnter` events, which are responsible for the scroll restoration. */
      noScroll: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        duration: 0,
        leaveDuration: TRANSITION_LEAVE_DURATION,
        enterDuration: TRANSITION_ENTER_DURATION,
      }
    },
    computed: {
      ...get('general', ['navigationDirection']),
      directionClass() {
        switch (this.navigationDirection) {
          case DIRECTION_BACK:
            return 'transition--direction-back'
          case DIRECTION_FORWARD:
            return 'transition--direction-forward'
          default:
            return 'transition--direction-none'
        }
      },
    },
  }
</script>

<template>
  <div
    :class="`transition ${directionClass} flex flex-col flex-grow`"
    :style="{ '--transition-duration': `${duration}ms` }"
  >
    <transition
      name="view"
      mode="out-in"
      :appear="appear"
      @leave="
        duration = leaveDuration
        !noScroll && $root.$emit('navigationTransitionLeave')
      "
      @enter="
        duration = enterDuration
        !noScroll && $root.$emit('navigationTransitionEnter')
      "
      @after-enter="$root.$emit('navigationTransitionComplete')"
    >
      <!-- @slot The current view. Should contain a RouterView component. -->
      <slot />
    </transition>
  </div>
</template>

<style lang="postcss" scoped>
  .view {
    &-leave-active,
    &-enter-active {
      @apply transform transition;

      transition-duration: var(--transition-duration);
    }

    &-leave-active {
      @apply ease-in;
    }

    &-enter-active {
      @apply ease-out;
    }

    &-leave-to,
    &-enter {
      @apply opacity-0;
    }
  }

  .transition {
    &--direction-none {
      .view {
        &-leave-to {
          @apply scale-95;
        }

        &-enter {
          @apply scale-105;
        }
      }
    }

    &--direction-back {
      .view {
        &-leave-to {
          @apply translate-x-16;
        }

        &-enter {
          @apply -translate-x-16;
        }
      }
    }

    &--direction-forward {
      .view {
        &-leave-to {
          @apply -translate-x-16;
        }

        &-enter {
          @apply translate-x-16;
        }
      }
    }
  }
</style>
